/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../../components/es/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Nemovitosti"}>
        <SiteHeader />

        <Column className="--center pb--60 pt--60" name={"intro"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Real Estate"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"content"}>
          
          <ColumnWrap className="column__flex --left el--3" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/132/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/132/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/132/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/132/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/132/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/132/img-1_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":286}} content={"Modern Rustic Studio Apartment $849,000"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":467}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/132/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/132/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/132/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/132/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/132/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/132/img-2_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":286}} content={"Modern Rustic Studio Apartment $849,000"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":467}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/132/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/132/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/132/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/132/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/132/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/132/img-3_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":286}} content={"Modern Rustic Studio Apartment $849,000"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":400}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"blank"} layout={"l30"}>
        </Column>


        <Column className="css-g943z5 --full pb--80 pt--80" name={"contact"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Let's Get More for Your Real Estate\n</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape4" content={"Get in touch"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style='color: var(--color-dominant)'>Ing. Thomas Kamenovsky</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style='color: var(--color-dominant)'>2023 – Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}